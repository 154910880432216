// MovementsList.jsx
import React, { useState, useEffect } from 'react';
import axios from '../axios';
import AddMovementModal from './AddMovementModal';
import EditMovementModal from './EditMovementModal';
import { FaEdit, FaTrash } from 'react-icons/fa';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const MovementsList = ({ account }) => {
  const [movements, setMovements] = useState([]);
  const [filteredMovements, setFilteredMovements] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState(null);

  const [filters, setFilters] = useState({
    aorb: '',
    movimento: '',
    dateFrom: '',
    dateTo: '',
    tipo: '',
    pagato: '',
  });

  useEffect(() => {
    fetchMovements();
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line
  }, [filters, movements]);

  const fetchMovements = async () => {
    try {
      const response = await axios.get('/movements', { params: { account } });
      setMovements(response.data);
      setFilteredMovements(response.data);
    } catch (error) {
      console.error('Error fetching movements:', error);
    }
  };

  const applyFilters = () => {
    let filtered = movements;

    if (filters.aorb) {
      filtered = filtered.filter((m) => m.aorb === filters.aorb);
    }
    if (filters.movimento) {
      filtered = filtered.filter((m) => m.movimento === filters.movimento);
    }
    if (filters.dateFrom) {
      filtered = filtered.filter(
        (m) => new Date(m.scadenza) >= new Date(filters.dateFrom)
      );
    }
    if (filters.dateTo) {
      filtered = filtered.filter(
        (m) => new Date(m.scadenza) <= new Date(filters.dateTo)
      );
    }
    if (filters.tipo) {
      filtered = filtered.filter((m) => m.tipo === filters.tipo);
    }
    if (filters.pagato !== '') {
      filtered = filtered.filter(
        (m) => m.pagato === (filters.pagato === 'true')
      );
    }

    setFilteredMovements(filtered);
  };

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: filters[name] === value ? '' : value,
    });
  };

  const handleCurrentMonth = () => {
    const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    setFilters({
      ...filters,
      dateFrom: startOfMonth,
      dateTo: endOfMonth,
    });
  };

  const handleNextMonth = () => {
    const startOfNextMonth = dayjs().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    const endOfNextMonth = dayjs().add(1, 'month').endOf('month').format('YYYY-MM-DD');
    setFilters({
      ...filters,
      dateFrom: startOfNextMonth,
      dateTo: endOfNextMonth,
    });
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const openEditModal = (movement) => {
    setSelectedMovement(movement);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedMovement(null);
    setIsEditModalOpen(false);
  };

  const handleAddSuccess = () => {
    fetchMovements();
  };

  const handleEditSuccess = () => {
    fetchMovements();
  };

  const handleDelete = async (movementId) => {
    const confirmDelete = window.confirm(
      'Sei sicuro di voler eliminare questo movimento?'
    );
    if (confirmDelete) {
      try {
        await axios.delete(`/movements/${movementId}`);
        fetchMovements();
      } catch (error) {
        console.error('Error deleting movement:', error);
      }
    }
  };

  // Funzione per l’esportazione in Excel
  const exportToExcel = () => {
    const exportableData = filteredMovements.map(({ _id, ...movement }) => ({
      Scadenza: movement.scadenza,
      Descrizione: movement.descrizione,
      Cliente: movement.cliefor,
      Tipo: movement.tipo,
      Movimento: movement.movimento,
      Conto: movement.conto,
      Importo: movement.importo.toFixed(2),
      Pagato: movement.pagato ? 'Sì' : 'No',
      'A o B': movement.aorb,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Movements');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'movements.xlsx');
  };

  // Calcoli finali
  const totalIncoda = filteredMovements
  .filter((m) => Boolean(m.incoda))
  .reduce((sum, m) => sum + m.importo, 0)
  .toFixed(2);

const totalPagato = filteredMovements
  .filter((m) => !Boolean(m.incoda))
  .filter((m) => Boolean(m.pagato))
  .filter((m) => m.tipo === 'uscita')
  .reduce((sum, m) => sum + m.importo, 0)
  .toFixed(2);

const totalUscita = filteredMovements
  .filter((m) => !Boolean(m.pagato))
  .filter((m) => !Boolean(m.incoda))
  .filter((m) => m.tipo === 'uscita')
  .reduce((sum, m) => sum + m.importo, 0)
  .toFixed(2);

  const entrate = filteredMovements.filter((m) => m.tipo === 'entrata');
  const fixedExpenses = filteredMovements.filter(
    (m) => m.tipo !== 'entrata' && m.movimento === 'fissa'
  );
  const variableExpenses = filteredMovements.filter(
    (m) => m.tipo !== 'entrata' && m.movimento === 'variabile'
  );

  let contoselezionato;
  if (account === 'caveau') contoselezionato = 'Brand Group';
  if (account === 'personale') contoselezionato = 'Personale Casa';
  if (account === 'conto3') contoselezionato = 'Residenza Ambrogi';

  // -- Card singolo movimento --
  const MovementCard = ({ movement }) => {
    const isEntrata = movement.tipo === 'entrata';
    const isCoda = movement.incoda === true;

    // Se è entrata => bordo verde, altrimenti rosso
    const borderClass = isEntrata
      ? 'border-green-400'
      : 'border-red-400';

    return (
      <li
        className={`relative p-4 border-l-4 ${borderClass} rounded-md shadow-sm hover:shadow-md transition`}
      >
        {/* Badge in alto a destra per Tipo Entrata / Uscita */}
        <span
          className={`absolute right-2 top-2 px-2 py-1 text-xs font-bold rounded ${
            isCoda ? 'bg-blue-700 text-white' : 'bg-red-100 text-red-700'
          }`}
        >
          {isCoda ? 'IN CODA' : 'USCITA'}
        </span>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          
          <div>
            {/* Mostro “Movimento” solo se è un’uscita */}
            {!isEntrata && (
              <p>
                <strong>Movimento:</strong> {movement.movimento}
              </p>
            )}
             <p>
              <strong>Descrizione:</strong> {movement.descrizione}
            </p>
            <p>
              <strong>Fornitore:</strong> {movement.cliefor}
            </p>
            <p>
              <strong>Conto:</strong> {movement.conto}
            </p>
            <p>
              <strong>Importo:</strong> {movement.importo}
            </p>
          
          </div>
        </div>

        {/* Se è un’uscita, mostro Pagato / Non Pagato */}
        {!isEntrata && (
          <div className="mt-2">
            <span
              className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${
                movement.pagato
                  ? 'bg-green-100 text-green-700'
                  : 'bg-red-100 text-red-700'
              }`}
            >
              {movement.pagato ? 'Pagato' : 'Non Pagato'}
            </span>
          </div>
        )}

        {/* Pulsanti Edit/Delete */}
        <div className="flex justify-end gap-2 mt-4">
          <button
            onClick={() => openEditModal(movement)}
            className="bg-indigo-50 text-indigo-600 p-2 rounded hover:bg-indigo-100 transition"
          >
            <FaEdit />
          </button>
          <button
            onClick={() => handleDelete(movement._id)}
            className="bg-red-50 text-red-600 p-2 rounded hover:bg-red-100 transition"
          >
            <FaTrash />
          </button>
        </div>
      </li>
    );
  };

  return (
    <div className="max-w-7xl mx-auto p-4 mb-12">
      {/* Titolo + pulsanti principali */}
      <div className="bg-white rounded-md shadow-md p-6 mb-6">
        <h1 className="text-3xl font-bold mb-4 text-gray-700">
          Movimenti Conto {contoselezionato}
        </h1>
        <div className="flex flex-wrap justify-between items-center gap-3">
          <button
            onClick={openAddModal}
            className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 transition"
          >
            Aggiungi Movimento
          </button>
          <div className="flex gap-2">
            <button
              onClick={handleCurrentMonth}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
            >
              Mese Corrente
            </button>
            <button
              onClick={handleNextMonth}
              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition"
            >
              Successivo
            </button>
            <button
              onClick={exportToExcel}
              className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition"
            >
              Esporta XLS
            </button>
          </div>
        </div>
      </div>

      {/* Sezione Filtro */}
      <div className="bg-white rounded-md shadow-md p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block mb-2 text-gray-600">Data di Scadenza Da</label>
            <input
              type="date"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={(e) => handleFilterChange('dateFrom', e.target.value)}
              className="border p-2 w-full rounded"
            />
          </div>
          <div>
            <label className="block mb-2 text-gray-600">Data di Scadenza A</label>
            <input
              type="date"
              name="dateTo"
              value={filters.dateTo}
              onChange={(e) => handleFilterChange('dateTo', e.target.value)}
              className="border p-2 w-full rounded"
            />
          </div>
        </div>

        {/* Filtri rapidi: A/B, fissa/variabile, ecc. */}
        <div className="flex flex-wrap gap-6 mb-2">
 

          <div>
            <p className="mb-2 text-gray-600 font-semibold">Movimento</p>
            <div className="flex gap-2">
              <button
                className={`px-4 py-2 rounded ${
                  filters.movimento === 'fissa'
                    ? 'bg-indigo-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
                onClick={() => handleFilterChange('movimento', 'fissa')}
              >
                Spesa Fissa
              </button>
              <button
                className={`px-4 py-2 rounded ${
                  filters.movimento === 'variabile'
                    ? 'bg-indigo-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
                onClick={() => handleFilterChange('movimento', 'variabile')}
              >
                Spesa Variabile
              </button>
            </div>
          </div>

 

          <div>
            <p className="mb-2 text-gray-600 font-semibold">Pagato</p>
            <div className="flex gap-2">
              <button
                className={`px-4 py-2 rounded ${
                  filters.pagato === 'true'
                    ? 'bg-indigo-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
                onClick={() => handleFilterChange('pagato', 'true')}
              >
                Sì
              </button>
              <button
                className={`px-4 py-2 rounded ${
                  filters.pagato === 'false'
                    ? 'bg-indigo-500 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
                onClick={() => handleFilterChange('pagato', 'false')}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Sezioni elenco movimenti */}
      <div className="space-y-8">
        {/* Entrate */}
        <div className="bg-white rounded-md shadow-md p-6">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Entrate</h2>
          <ul className="space-y-4">
            {entrate.map((movement) => (
              <MovementCard key={movement._id} movement={movement} />
            ))}
          </ul>
        </div>

        {/* Spese Fisse */}
        <div className="bg-white rounded-md shadow-md p-6">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Spese Fisse</h2>
          <ul className="space-y-4">
            {fixedExpenses.map((movement) => (
              <MovementCard key={movement._id} movement={movement} />
            ))}
          </ul>
        </div>

        {/* Spese Variabili */}
        <div className="bg-white rounded-md shadow-md p-6">
          <h2 className="text-xl font-bold mb-4 text-gray-700">Spese Variabili</h2>
          <ul className="space-y-4">
            {variableExpenses.map((movement) => (
              <MovementCard key={movement._id} movement={movement} />
            ))}
          </ul>
        </div>
      </div>

      {/* Barra fissa con Totale Entrate/Uscite */}
      <div className="fixed bottom-0 left-0 right-0 flex z-10">
  {/* Colonna 1 - In Coda (blu) */}
  <div className="flex-1 bg-blue-500 py-4 px-6 text-white text-center">
    <p>
      <strong>Totale In coda:</strong> {totalIncoda}
    </p>
  </div>

  {/* Colonna 2 - Uscita (rossa) */}
  <div className="flex-1 bg-red-500 py-4 px-6 text-white text-center">
    <p>
      <strong>Totale Uscita:</strong> {totalUscita}
    </p>
  </div>

  {/* Colonna 3 - Pagato (verde) */}
  <div className="flex-1 bg-green-500 py-4 px-6 text-white text-center">
    <p>
      <strong>Totale Pagato:</strong> {totalPagato}
    </p>
  </div>
</div>

      {/* Modali di aggiunta e modifica */}
      <AddMovementModal
        isOpen={isAddModalOpen}
        onRequestClose={closeAddModal}
        account={account}
        onAddSuccess={handleAddSuccess}
      />
      <EditMovementModal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        account={account}
        movement={selectedMovement}
        onEditSuccess={handleEditSuccess}
      />
    </div>
  );
};

export default MovementsList;
