// src/components/EditMovementModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from '../axios';

Modal.setAppElement('#root'); // Necessario per accessibilità

const EditMovementModal = ({
  isOpen,
  onRequestClose,
  account,
  movement,
  onEditSuccess,
}) => {
  const [form, setForm] = useState({
    scadenza: '',
    descrizione: '',
    cliefor: '',
    tipo: '',
    movimento: '',
    importo: 0,
    aorb: '',
    pagato: false,
    __v: 0,
  });

  useEffect(() => {
    if (movement) {
      setForm({
        scadenza: movement.scadenza
          ? new Date(movement.scadenza).toISOString().split('T')[0]
          : '',
        descrizione: movement.descrizione,
        cliefor: movement.cliefor,
        tipo: movement.tipo,
        movimento: movement.movimento,
        importo: movement.importo,
        incoda: movement.incoda || false,
        aorb: movement.aorb,
        pagato: movement.pagato,
        __v: movement.__v, // Manteniamo la versione per il controllo di conflitto
      });
    }
  }, [movement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleTipoChange = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      tipo: value,
      cliefor: '',
      // Se cambia il tipo da entrata a uscita (o viceversa), resettiamo i campi
      movimento: value === 'uscita' ? form.movimento : '',
      pagato: value === 'uscita' ? form.pagato : false,
    });
  };

  const handleMovimentoChange = (value) => {
    setForm({
      ...form,
      // Se clicchiamo di nuovo sullo stesso, lo deselezioniamo
      movimento: form.movimento === value ? '' : value,
    });
  };

  const handleAorbChange = (value) => {
    setForm({
      ...form,
      aorb: form.aorb === value ? '' : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.aorb) {
      alert('Devi selezionare A o B');
      return;
    }
    // Solo se si tratta di un'uscita, imponiamo la scelta tra "Spesa Fissa" o "Spesa Variabile"
    if (form.tipo === 'uscita' && !form.movimento) {
      alert('Devi selezionare Spesa Fissa o Spesa Variabile');
      return;
    }

    const movementData = {
      ...form,
      conto: account,
      __v: movement.__v,
    };

    try {
      await axios.patch(`/movements/${movement._id}`, movementData);
      alert('Movimento aggiornato con successo!');
      onEditSuccess();
      onRequestClose();
    } catch (error) {
      if (error.response?.status === 409) {
        alert(
          '⚠️ Conflitto: un altro utente ha già modificato questo movimento. Ricarica la pagina e riprova.'
        );
      } else {
        alert('Errore durante l\'aggiornamento.');
      }
      console.error(
        'Errore durante l\'aggiornamento:',
        error.response?.data || error.message
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="overlay"
    >
      <h1 className="text-2xl font-bold mb-4">Modifica movimento</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-2">Scadenza</label>
          <input
            type="date"
            name="scadenza"
            value={form.scadenza}
            onChange={handleChange}
            className="border p-2 w-full"
            required
          />
        </div>

        <div>
          <label className="block mb-2">Descrizione</label>
          <input
            type="text"
            name="descrizione"
            value={form.descrizione}
            onChange={handleChange}
            className="border p-2 w-full"
            required
          />
        </div>
 

        {/* Se è entrata => "Cliente", altrimenti "Fornitore" */}
        <div>
          <label className="block mb-2">
            {form.tipo === 'entrata' ? 'Cliente' : 'Fornitore'}
          </label>
          <input
            type="text"
            name="cliefor"
            value={form.cliefor}
            onChange={handleChange}
            className="border p-2 w-full"
            required
          />
        </div>
        <div className="flex items-center space-x-3">
    <span className="block text-sm font-medium text-gray-700">In coda</span>
    <div
      onClick={() => setForm({ ...form, incoda: !form.incoda })}
      className={`relative w-10 h-6 cursor-pointer 
        ${form.incoda ? 'bg-green-500' : 'bg-gray-300'} 
        rounded-full transition-colors duration-200 ease-in-out`}
    >
      <div
        className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform 
          duration-200 ease-in-out
          ${form.incoda ? 'translate-x-4' : ''}`}
      />
    </div>
    <span className="text-sm font-semibold">
      {form.incoda ? 'Sì' : 'No'}
    </span>
  </div>
          <div>
            <label className="block mb-2">Movimento</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="checkbox"
                  name="movimento"
                  checked={form.movimento === 'fissa'}
                  onChange={() => handleMovimentoChange('fissa')}
                  className="mr-2"
                />
                Spesa Fissa
              </label>
              <label>
                <input
                  type="checkbox"
                  name="movimento"
                  checked={form.movimento === 'variabile'}
                  onChange={() => handleMovimentoChange('variabile')}
                  className="mr-2"
                />
                Spesa Variabile
              </label>
            </div>
          </div>
 
        <div>
          <label className="block mb-2">Importo</label>
          <input
            type="number"
            name="importo"
            value={form.importo}
            onChange={handleChange}
            className="border p-2 w-full"
            required
          />
        </div>

 

        {/* Campo Pagato mostrato SOLO se è un’uscita */}

        {form.tipo === 'uscita' && (
  <div className="flex items-center space-x-3">
    <span className="block text-sm font-medium text-gray-700">Pagato</span>

    {/* Contenitore dello switch */}
    <div
      onClick={() => setForm({ ...form, pagato: !form.pagato })}
      className={`relative w-10 h-6 cursor-pointer 
        ${form.pagato ? 'bg-green-500' : 'bg-gray-300'} 
        rounded-full transition-colors duration-200 ease-in-out`}
    >
      {/* Pallino interno */}
      <div
        className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform 
          duration-200 ease-in-out
          ${form.pagato ? 'translate-x-4' : ''}`}
      />
    </div>

    <span className="text-sm font-semibold">
      {form.pagato ? 'Sì' : 'No'}
    </span>
  </div>
)}


        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Salva
        </button>
      </form>
    </Modal>
  );
};

export default EditMovementModal;
