// src/components/AddMovementModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from '../axios';
import { useEffect } from 'react';
Modal.setAppElement('#root'); // Necessario per accessibilità



const AddMovementModal = ({ isOpen, onRequestClose, account, onAddSuccess }) => {
  const [form, setForm] = useState({
    scadenza: '',
    descrizione: '',
    cliefor: '',
    tipo: '',
    movimento: '',
    importo: 0,
    aorb: 'A', // <-- forzato su A
    pagato: false,
    incoda: false,
  });

  // Ogni volta che si apre il modale,
  // puoi eventualmente reimpostare form se desideri
  // ma se lo vuoi "pulito" sempre, lascia così e basta.

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleTipoChange = (e) => {
    const { value } = e.target;
    // Se cambiamo tipo, resettiamo "movimento" e "pagato" se non è più uscita
    setForm({
      ...form,
      tipo: value,
      cliefor: '',
      movimento: value === 'uscita' ? form.movimento : '',
      pagato: value === 'uscita' ? form.pagato : false,
    });
  };

  const handleMovimentoChange = (value) => {
    // Se clicchiamo di nuovo sullo stesso bottone, lo deselezioniamo
    setForm({
      ...form,
      movimento: form.movimento === value ? '' : value,
    });
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();


    // Solo se si tratta di un'uscita, imponiamo la scelta tra "Spesa Fissa" o "Spesa Variabile"
    if (form.tipo === 'uscita' && !form.movimento) {
      alert('Devi selezionare Spesa Fissa o Spesa Variabile');
      return;
    }

    const movementData = {
      ...form,
      conto: account,
    };

    try {
      await axios.post('/movements', movementData);
      alert('Movimento inserito correttamente');
      onAddSuccess(); // Notifica il successo dell'aggiunta
      onRequestClose(); // Chiude il modale
    } catch (error) {
      console.error('Error adding movement:', error);
      alert('Errore durante l\'inserimento del movimento.');
    }
  };
  useEffect(() => {
    if (isOpen) {
      setForm({
        scadenza: '',
        descrizione: '',
        cliefor: '',
        tipo: 'uscita',
        movimento: '',
        importo: 0,
        aorb: 'A',
        pagato: false,
        incoda: false,
      });
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="overlay"
    >
      <h1 className="text-2xl font-bold mb-4">Aggiungi Movimento</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Scadenza */}
        <div>
          <label className="block mb-2">Scadenza</label>
          <input
            type="date"
            name="scadenza"
            value={form.scadenza}
            onChange={handleChange}
            className="border p-2 w-full rounded"
            required
          />
        </div>

        {/* Descrizione */}
        <div>
          <label className="block mb-2">Descrizione</label>
          <input
            type="text"
            name="descrizione"
            value={form.descrizione}
            onChange={handleChange}
            className="border p-2 w-full rounded"
            required
          />
        </div>

        {/* Tipo */}
 

        {/* Cliente/Fornitore */}
        <div>
          <label className="block mb-2">
            Fornitore
          </label>
          <input
            type="text"
            name="cliefor"
            value={form.cliefor}
            onChange={handleChange}
            className="border p-2 w-full rounded"
            required
          />
        </div>

        {/* Movimento e Pagato => SOLO se è uscita */}
        {form.tipo === 'uscita' && (
          <>
            {/* Movimento */}
            <div>
              <label className="block mb-2">Movimento</label>
              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`p-2 rounded ${
                    form.movimento === 'fissa'
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 hover:bg-gray-300'
                  }`}
                  onClick={() => handleMovimentoChange('fissa')}
                >
                  Spesa Fissa
                </button>
                <button
                  type="button"
                  className={`p-2 rounded ${
                    form.movimento === 'variabile'
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 hover:bg-gray-300'
                  }`}
                  onClick={() => handleMovimentoChange('variabile')}
                >
                  Spesa Variabile
                </button>
              </div>
            </div>

            {/* Pagato (toggle) */}
            <div className="flex items-center space-x-3">
              <span className="block text-sm font-medium text-gray-700">
                Pagato
              </span>
              {/* Contenitore dello switch */}
              <div
                onClick={() => setForm({ ...form, pagato: !form.pagato })}
                className={`relative w-10 h-6 cursor-pointer 
                  ${form.pagato ? 'bg-green-500' : 'bg-gray-300'} 
                  rounded-full transition-colors duration-200 ease-in-out`}
              >
                {/* Pallino interno */}
                <div
                  className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform 
                    duration-200 ease-in-out
                    ${form.pagato ? 'translate-x-4' : ''}`}
                />
              </div>

              <span className="text-sm font-semibold">
                {form.pagato ? 'Sì' : 'No'}
              </span>
            </div>
            
        {/* In coda (toggle) */}
<div className="flex items-center space-x-3">
  <span className="block text-sm font-medium text-gray-700">
    In coda
  </span>
  {/* Contenitore dello switch */}
  <div
    onClick={() => setForm({ ...form, incoda: !form.incoda })}
    className={`relative w-10 h-6 cursor-pointer 
      ${form.incoda ? 'bg-green-500' : 'bg-gray-300'} 
      rounded-full transition-colors duration-200 ease-in-out`}
  >
    {/* Pallino interno */}
    <div
      className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform 
        duration-200 ease-in-out
        ${form.incoda ? 'translate-x-4' : ''}`}
    />
  </div>

  <span className="text-sm font-semibold">
    {form.incoda ? 'Sì' : 'No'}
  </span>
</div>
          </>
        )}

        {/* Importo */}
        <div>
          <label className="block mb-2">Importo</label>
          <input
            type="number"
            name="importo"
            value={form.importo}
            onChange={handleChange}
            className="border p-2 w-full rounded"
            step="any"
            required
          />
        </div>



        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
        >
          Salva
        </button>
      </form>
    </Modal>
  );
};

export default AddMovementModal;
